export const MarkerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    viewBox="0 0 60 60"
    version="1.1"
  >
    <g>
      <path
        stroke="#ccc"
        fill="#ccc"
        d="M 30.132812 14.660156 C 34.84375 14.660156 38.632812 18.203125 38.632812 22.914062 C 38.632812 27.4375 34.84375 31.207031 30.132812 31.207031 C 25.382812 31.207031 21.59375 27.4375 21.59375 22.914062 C 21.59375 18.203125 25.382812 14.660156 30.132812 14.660156 Z M 51.40625 20.804688 C 51.40625 9.234375 41.953125 0 30.132812 0 C 18.296875 0 8.59375 9.234375 8.59375 20.804688 C 8.59375 21.292969 8.59375 22.011719 8.839844 22.460938 L 8.59375 22.460938 C 8.59375 33.808594 30.132812 60 30.132812 60 C 30.132812 60 51.40625 33.808594 51.40625 22.460938 Z M 51.40625 20.804688 "
      />
    </g>
  </svg>
);
