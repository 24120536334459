export const MapSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60px"
    height="60px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g>
      <path
        stroke="none"
        fill="#ccc"
        d="M 6.65625 25.46875 C 6.65625 24.671875 6.015625 24.03125 5.21875 24.03125 C 4.421875 24.03125 3.78125 24.671875 3.78125 25.46875 C 3.78125 26.265625 4.421875 26.90625 5.21875 26.90625 C 6.015625 26.90625 6.65625 26.265625 6.65625 25.46875 Z M 6.65625 25.46875 "
      />
      <path
        stroke="none"
        fill="#ccc"
        d="M 36.96875 15.4375 C 36.96875 14.640625 36.328125 14 35.53125 14 C 34.734375 14 34.09375 14.640625 34.09375 15.4375 C 34.09375 16.234375 34.734375 16.875 35.53125 16.875 C 36.328125 16.875 36.96875 16.234375 36.96875 15.4375 Z M 36.96875 15.4375 "
      />
      <path
        stroke="none"
        fill="#ccc"
        d="M 25.90625 23.875 C 25.40625 23.875 24.9375 23.8125 24.5 23.6875 L 24.8125 22.59375 C 25.25 22.71875 25.8125 22.75 26.3125 22.71875 L 26.40625 23.84375 C 26.25 23.875 26.09375 23.875 25.90625 23.875 Z M 28.28125 23.3125 L 27.71875 22.3125 C 28.125 22.09375 28.46875 21.78125 28.78125 21.34375 L 29.71875 22 C 29.3125 22.59375 28.84375 23 28.28125 23.3125 Z M 6.34375 23.125 L 5.21875 22.8125 C 5.375 22.25 5.5625 21.6875 5.75 21.15625 L 6.84375 21.5625 C 6.65625 22.03125 6.5 22.5625 6.34375 23.125 Z M 22.78125 22.75 C 22.375 22.375 21.96875 21.90625 21.59375 21.3125 L 22.5625 20.6875 C 22.90625 21.1875 23.21875 21.59375 23.5625 21.875 Z M 30.625 20.4375 L 29.5625 19.96875 C 29.65625 19.75 29.78125 19.5 29.875 19.28125 C 30 19 30.125 18.6875 30.28125 18.40625 L 31.3125 18.90625 C 31.1875 19.1875 31.0625 19.46875 30.9375 19.75 C 30.84375 19.96875 30.75 20.1875 30.625 20.4375 Z M 7.5 20.03125 L 6.46875 19.53125 C 6.75 18.96875 7.03125 18.46875 7.34375 17.96875 L 8.3125 18.5625 C 8 19.03125 7.75 19.5 7.5 20.03125 Z M 20.71875 19.8125 L 20.59375 19.59375 C 20.375 19.1875 20.125 18.75 19.875 18.34375 L 20.875 17.78125 C 21.125 18.21875 21.375 18.65625 21.59375 19.09375 L 21.71875 19.3125 Z M 32.15625 17.53125 L 31.21875 16.84375 C 31.65625 16.28125 32.125 15.875 32.65625 15.5625 L 33.21875 16.5625 C 32.84375 16.78125 32.46875 17.09375 32.15625 17.53125 Z M 9.28125 17.3125 L 8.40625 16.5625 C 8.8125 16.09375 9.28125 15.6875 9.75 15.3125 L 10.4375 16.21875 C 10.03125 16.53125 9.625 16.875 9.28125 17.3125 Z M 18.96875 17 C 18.625 16.5625 18.25 16.21875 17.84375 15.9375 L 18.53125 15 C 19.03125 15.34375 19.46875 15.75 19.875 16.28125 Z M 11.8125 15.4375 L 11.34375 14.375 C 11.90625 14.125 12.53125 13.96875 13.15625 13.875 L 13.3125 15 C 12.78125 15.09375 12.28125 15.21875 11.8125 15.4375 Z M 16.46875 15.25 C 16 15.09375 15.46875 15 14.90625 14.96875 L 15 13.84375 C 15.65625 13.90625 16.28125 14 16.8125 14.1875 Z M 16.46875 15.25 "
      />
      <path
        stroke="none"
        fill="#ccc"
        d="M 29.625 4.46875 L 20.03125 8.375 L 10.375 4.46875 L 0 8.6875 L 0 35.28125 L 10.375 31.09375 L 19.875 34.9375 L 29.625 31.34375 L 40 35.59375 L 40 8.71875 Z M 38.25 32.96875 L 30.5 29.78125 L 30.5 21.90625 C 30.0625 22.59375 29.53125 23.25 28.75 23.71875 L 28.75 29.75 L 20.75 32.6875 L 20.75 21.0625 C 20.5 20.65625 20.3125 20.28125 20.09375 19.875 C 19.75 19.21875 19.40625 18.59375 19.03125 18.03125 L 19.03125 32.71875 L 11.25 29.5625 L 11.25 16.375 C 10.59375 16.78125 10 17.3125 9.5 17.90625 L 9.5 29.5625 L 1.75 32.71875 L 1.75 9.8125 L 9.53125 6.65625 L 9.53125 14.75 C 10.0625 14.375 10.625 14.0625 11.25 13.8125 L 11.25 6.6875 L 19.03125 9.84375 L 19.03125 14.6875 C 19.71875 15.21875 20.28125 15.84375 20.78125 16.5 L 20.78125 9.9375 L 28.75 6.6875 L 28.75 20.3125 C 28.9375 19.9375 29.15625 19.53125 29.34375 19.0625 C 29.65625 18.375 30 17.625 30.46875 16.90625 L 30.46875 6.6875 L 38.25 9.875 Z M 38.25 32.96875 "
      />
    </g>
  </svg>
);
